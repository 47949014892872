<template>
  <v-container id="newsevents" class="ma-auto pa-0" fluid>
    <v-row class="pa-0 ma-0 main-modified">
      <v-col cols="12" class="ma-0 pa-0">
        <v-row class="pa-0 ma-0" :style="{'background-image': 'url(' + storage_url+path+'news-events-bg.jpg' + ')'}" style="height:300px;filter: grayscale(0.1);background-repeat: no-repeat;background-size: cover;">
          <v-col class="text-center ma-auto">
            <h1 class="text-h4 custom-white--text text-uppercase" style="-webkit-text-stroke: 0.4px black;">{{$t("news")+' & '+$t("events")}}</h1>
          </v-col>
        </v-row>
        <v-row class="ma-0 pa-0 pt-2">
          <v-col cols="12" sm="5" order="2" order-sm="1" class="ma-0 py-0">
            <v-sheet
              elevation="0"
              class="ma-auto ma-sm-0 mr-sm-auto pa-0 transparent"
            >
              <v-chip-group
                mandatory
                multiple
                show-arrows
                v-model="activefilters"
                active-class="custom-accent custom-white--text"
              >
                <v-chip
                  v-for="filter in filters"
                  :key="filter"
                  :value="filter"
                >
                  {{ $t(filter) }}
                </v-chip>
              </v-chip-group>
            </v-sheet>
          </v-col>
          <v-col cols="12" sm="7" order="1" order-sm="2" class="ma-0 pa-1">
            <v-text-field
              v-model="Search.SearchTerm"
              :label="$t('search')"
              :placeholder="$t('search')"
              class="search-box ma-auto ma-sm-0 ml-sm-auto pa-0"
              color="custom-accent"
              rounded
              clearable
              outlined
              dense
              @click:clear="Search.SearchTerm='';search(Search.SearchTerm)"
              @keyup="search(Search.SearchTerm)"
              append-icon="mdi-magnify"
              hide-details
              return-object
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="10" lg="8" class="ma-auto">
            <transition name="fade" mode="out-in" v-if="blog_loading || Search.SearchLoading">
              <v-row class="px-8 ma-0" justify="center">
                <v-col
                  v-for="i in itemsperpage"
                  :key="i"
                  cols="12"
                  sm="10"
                  md="8"
                >
                  <v-skeleton-loader
                    type="image"
                  ></v-skeleton-loader>
                </v-col>
              </v-row>
            </transition>
            <transition name="fade" mode="out-in" v-if="Search.SearchResult.length==0 && !Search.SearchLoading && !blog_loading">
              <v-row class="text-center pa-12 ma-auto" ><i class="ma-auto text-h4 text--secondary">{{$t("noresult")}}<span v-if="Search.SearchTerm">{{$t("resultfor")}} "{{Search.SearchTerm}}"</span>.</i></v-row>
            </transition>
            <transition name="fade" mode="out-in" v-if="blogs.length && Search.SearchResult.length && !Search.SearchLoading && !blog_loading">
              <v-row class="px-8 ma-0" justify="center" :key="itemsperpage * page">
                <v-col v-if="user && isLoggedIn && user.permission.map(i=>i.name).includes('blogs.create')"
                  cols="12"
                  sm="10"
                  md="8">
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn block height="60" x-large v-bind="attrs" v-on="on" @click="OpenCreateBlog()" text outlined :title="$t('create')"><v-icon class="text--secondary" large>mdi-plus-box</v-icon></v-btn>
                    </template>
                    <span>{{$t('create')}}</span>
                  </v-tooltip>
                </v-col>
                <v-col
                  v-for="(item, i) in Search.SearchResult.slice(
                    itemsperpage * (page - 1),
                    itemsperpage * page
                  )"
                  :key="i"
                  cols="12"
                  sm="10"
                  md="8"
                >
                  <v-card
                    class="elevation-0"
                    outlined
                    @click="ViewBlog(item.id)"
                  >
                    <div class="text-center text-sm-left">
                      <v-row class="pa-0 ma-0" style="position:absolute;right:-75px;top:0px;" v-if="user && isLoggedIn">
                        <v-col class="pa-0 ma-0" cols="12">
                          <v-tooltip right v-if="user && isLoggedIn && user.permission.map(i=>i.name).includes('blogs.update')">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon v-bind="attrs" v-on="on" @click.stop="OpenUpdateBlog(item)" :title="$t('update')">
                                <v-icon>mdi-pencil</v-icon>
                              </v-btn>
                            </template>
                            <span>{{$t('update')}}</span>
                          </v-tooltip>
                        </v-col>
                        <v-col class="pa-0 ma-0" cols="12">
                          <v-tooltip right v-if="user && isLoggedIn && user.permission.map(i=>i.name).includes('blogs.delete')">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon v-bind="attrs" v-on="on" @click.stop="OpenDeleteBlog(item)" :title="$t('delete')">
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                            </template>
                            <span>{{$t('delete')}}</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                      <v-card-title class="mb-2 custom-accent--text"><v-badge left :content="$t(item.category)" color="custom-accent"><span class="v-html-line-clamp-1 text-h6">{{$i18n.locale == "en" ? item.titleEN : item.titleFR}}</span></v-badge></v-card-title>
                      <v-card-subtitle class="subtitle-2">{{$t("by")}} <span class="text-capitalize" v-if="item.author">{{$getObjectById(users,item.author).fullname}}</span><span v-else>{{$t('admin')}}</span> | {{item.created_at | formatDate}}</v-card-subtitle>
                      <v-card-text class="py-0 custom-secondary--text"><span :class="{ 'v-html-line-clamp-2': i != expandableBlog}" v-html="$i18n.locale == 'en'? item.descriptionEN: item.descriptionFR"></span></v-card-text>
                      <v-card-actions>
                        <v-chip v-if="item.image" color="secondary" class="mr-auto"><v-icon class="mr-2" dark>mdi-image-area</v-icon><i>{{$t("imageavailable")}}</i></v-chip>
                        <v-btn color="custom-accent" :title="$t('viewmore')" class="ml-auto" v-if="i != expandableBlog" text @click.stop="ExpandBlog(i)">{{$t("viewmore")}}</v-btn>
                        <v-btn color="custom-accent" :title="$t('viewless')" class="ml-auto" v-else text @click.stop="expandableBlog=null">{{$t("viewless")}}</v-btn>
                        <v-btn color="custom-accent" :title="$t('comment')" text @click.stop="ViewBlog(item.id)">{{$t("comment")}}</v-btn>
                      </v-card-actions>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </transition>
            <v-row class="pa-0 ma-0 mb-4">
              <v-col v-if="Math.ceil(Search.SearchResult.length / itemsperpage)>1">
                <v-pagination
                  v-model="page"
                  :length="Math.ceil(Search.SearchResult.length / itemsperpage)"
                  color="custom-accent"
                  :total-visible="7"
                  circle
                ></v-pagination>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <!-- view dialog -->
        <v-dialog v-model="blogdetailsoverlay" width="1100px" scrollable>
          <v-card class="pa-2 overflow-hidden" v-if="blogdetailsoverlay">
            <v-card-title class="pa-0 ma-0 pa-4 mr-6">
              <span class="text-h5 custom-accent--text">{{
                $i18n.locale == "en"
                  ? activeblog.titleEN
                  : activeblog.titleFR
              }}</span><v-spacer></v-spacer>
              <v-tooltip left color="custom-secondary">
              <template v-slot:activator="{ on, attrs }">
                <v-btn absolute right top
                  style="z-index: 3;top:-1px;right:-2px"
                  v-bind="attrs"
                  v-on="on"
                  :title="$t('close')"
                  @click="blogdetailsoverlay = false"
                  icon
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("close") }}</span>
            </v-tooltip>
            </v-card-title>
            <v-card-subtitle class="subtitle-2 custom-secondary--text pa-0 ma-0 mx-4" v-if="blogdetailsoverlay"><v-badge inline :content="$t(activeblog.category)" color="custom-accent"></v-badge>{{$t("by")}} <span class="text-capitalize" v-if="activeblog.author">{{$getObjectById(users,activeblog.author).fullname}}</span><span v-else>{{$t('admin')}}</span> | {{activeblog.created_at | formatDate}}</v-card-subtitle>
            <v-divider class="my-4"></v-divider>
            <v-card-text class="subtitle-2 custom-secondary--text">
              <v-img v-if="activeblog.image"
                :src="storage_url+path+activeblog.image"
                :alt="activeblog.alt"
                aspect-ratio="1"
                class="ma-auto mb-8"
                max-height="600"
                contain
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="custom-secondary"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <span v-html="$i18n.locale == 'en'? activeblog.descriptionEN: activeblog.descriptionFR"></span>
              <span class="text-uppercase text-h6 custom-accent--text">{{$t("leaveacomment")}}</span>
              <contact-us-form class="my-5" :isDark="false" :type="'newsevents-'+activeblog.id"></contact-us-form>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- view dialog -->

        <!-- create dialog -->
        <v-dialog v-model="blogcreateoverlay" width="800px">
          <v-card v-if="blogcreateoverlay">
            <v-card-title class="subtitle-1 text-uppercase custom-light-gray">
                <span class="ma-auto">{{$t("additem")}}</span>
            </v-card-title>
            <v-card-text class="pa-4 ma-0">
              <file-input-drop @upload="UploadFiles" :allowedTypes="allowedTypes" :allowedSize="allowedSize" :allowedLength="allowedLength" :multiple="multiple"></file-input-drop>
              <create-blog @isValid="validate" :NewItem="activeblog"></create-blog>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed :title="$t('close')" @click="blogcreateoverlay = false">{{$t('close')}}</v-btn>
              <v-btn dark depressed :title="$t('clear')" @click="ClearBlog()">{{$t('clear')}}</v-btn>
              <v-btn class="success" :title="$t('create')" depressed :loading="blog_loading || file_loading" :disabled="!blogcreateformvalid" @click="CreateBlog()">
                <span>{{$t('create')}}</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- create dialog -->

        <!-- update dialog -->
        <v-dialog v-model="blogupdateoverlay" width="800px">
          <v-card v-if="blogupdateoverlay">
            <v-card-title class="subtitle-1 text-uppercase custom-light-gray">
                <span class="ma-auto">{{$t("updateitem")}}</span>
            </v-card-title>
            <v-card-text class="pa-4 ma-0">
               <file-input-drop v-if="!activeblog.image" @upload="UploadFiles" :allowedTypes="allowedTypes" :allowedSize="allowedSize" :allowedLength="allowedLength" :multiple="multiple"></file-input-drop>
              <update-blog @isValid="validate" @deleteFile="OpenDeleteFile" :UpdatedItem="activeblog"></update-blog>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed :title="$t('close')" @click="blogupdateoverlay = false">{{$t('close')}}</v-btn>
              <v-btn class="success" :title="$t('update')" :loading="blog_loading" depressed :disabled="!blogupdateformvalid" @click="UpdateBlog()">
                <span>{{$t('update')}}</span>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- update dialog -->

        <!-- delete dialog -->
        <v-dialog v-model="blogdeleteoverlay" width="500px">
          <v-card>
            <v-card-title class="subtitle-1 text-uppercase custom-light-gray">
                <span class="ma-auto">{{$t("deleteitem")}}</span>
            </v-card-title>
            <v-card-text class="pa-4 ma-0">{{$t("deleteconfirmation")}}</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed :title="$t('close')" @click="blogdeleteoverlay = false">{{$t('close')}}</v-btn>
              <v-btn class="error" :title="$t('delete')" :loading="blog_loading" depressed @click="DeleteBlog()">{{$t('delete')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- delete dialog -->

        <!-- file delete dialog -->
        <v-dialog v-model="filedeleteoverlay" width="500px">
          <v-card>
            <v-card-title class="subtitle-1 text-uppercase custom-light-gray">
                <span class="ma-auto">{{$t("deleteitem")}}</span>
            </v-card-title>
            <v-card-text class="pa-4 ma-0">{{$t("deleteconfirmation")}}</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn depressed :title="$t('close')" @click="filedeleteoverlay = false">{{$t('close')}}</v-btn>
              <v-btn class="error" :title="$t('delete')" :loading="file_loading" depressed @click="DeleteFile()">{{$t('delete')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- file delete dialog -->
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import contactusform from '../../components/Contact/ContactUsForm.vue';
import createblog from '../../components/Blog/CreateBlog.vue';
import updateblog from '../../components/Blog/UpdateBlog.vue';
import fileinputdrop from "../../components/Admin/FileInputDrop.vue";
import { mapState,mapActions,mapGetters} from "vuex";
export default {
  name: "NewsEvents",

  data() {
    return {
      Search:{
        SearchTerm:'',
        SearchBox:false,
        SearchLoading:false,
        SearchResult:[],
      },
      filters:[
        'news','events','archived'
      ],
      activefilters:['news','events'],
      index: 0,
      blogdetailsoverlay: false,
      blogcreateoverlay: false,
      blogupdateoverlay: false,
      blogdeleteoverlay: false,
      blogcreateformvalid: false,
      blogupdateformvalid: false,
      expandableBlog: null,
      activeblog: {},
      page: 1,
      itemsperpage: 4,

      allowedTypes: "image/apng, image/bmp, image/gif, image/jpeg, image/pjpeg, image/png, image/svg+xml, image/tiff, image/webp, image/x-icon, image/jpg, image/vnd.microsoft.icon, image/svg+xml",
      allowedSize: "268435456",
      allowedLength: 20,
      multiple:false,
      files:[],
      filedeleteoverlay: false,

      storage_url: process.env.VUE_APP_STORAGEURL,
      path: "blogs/newsevents/images/",
    };
  },
  components: {
    'contact-us-form':contactusform,
    'create-blog':createblog,
    'update-blog':updateblog,
    'file-input-drop':fileinputdrop,
  },
  async created() {
    this.Search.SearchResult=this.blogs;
    this.search(this.Search.SearchTerm);
    if(this.$route.params.search && this.$route.params.blogid){
      this.activefilters=this.filters;
      this.Search.SearchTerm=this.$route.params.search;
      this.search(this.$route.params.search);
      this.ViewBlog(this.$route.params.blogid);
    }
  },
  computed: {
    ...mapGetters("user", {users:'getUsers'}),
    ...mapGetters("user",{user:'getUser',isLoggedIn:'isLoggedIn'}),
    ...mapGetters('blog', {blogs:'getBlogs'}),
    ...mapState("blog", {blog_loading: "loading"}),
    ...mapState("file", {file_loading: "loading"}),
  },
  watch: {
    blogs: {
        handler(){
            this.search(this.Search.SearchTerm);
        }, deep: true 
    },
    activefilters(){
      this.page=1;
      this.search(this.Search.SearchTerm);
    },
    $route() {
      if(this.$route.params.search && this.$route.params.blogid){
        this.activefilters=this.filters;
        this.search(this.$route.params.search);
        this.ViewBlog(this.$route.params.blogid);
      }
    },
    page(){
      this.$root.$emit('toTop');
    },
  },
  methods: {
    validate(context,isValid){
      switch (context) {
        case 'create':
            this.blogcreateformvalid=isValid;
            break;

        case 'update':
            this.blogupdateformvalid=isValid;
            break;

        default:
            break;
      }
    },
    search(item){
      this.Search.SearchLoading=true
      this.Search.SearchResult = '';
      if(item)
        this.Search.SearchResult = this.blogs.filter(blog => {
          if(this.$i18n.locale=='en'){
          return this.activefilters.includes(blog.category?.toLowerCase()) && (blog.titleEN?.toLowerCase().includes(item.toLowerCase()) || blog.descriptionEN?.toLowerCase().includes(item.toLowerCase())) ;
          }
          else{
          return this.activefilters.includes(blog.category?.toLowerCase()) && (blog.titleFR?.toLowerCase().includes(item.toLowerCase()) || blog.descriptionFR?.toLowerCase().includes(item.toLowerCase())) ;
          }
        });
      else
        this.Search.SearchResult=this.blogs.filter(blog => {
          return this.activefilters.includes(blog.category.toLowerCase())
          }
        );
      this.Search.SearchLoading=false;
    },
    ExpandBlog(i) {
      this.expandableBlog = i;
    },
    ViewBlog(id) {
      let blog = this.Search.SearchResult.filter(blog=>{
        return blog.id==id;
      })[0];
      this.blogdetailsoverlay = true;
      this.activeblog = blog;
    },
    OpenCreateBlog() {
      this.blogcreateoverlay = true;
      this.activeblog = {};
    },
    OpenUpdateBlog(item) {
      this.blogupdateoverlay = true;
      this.activeblog = {...item};
    },
    OpenDeleteBlog(item) {
      this.blogdeleteoverlay = true;
      this.activeblog = {...item};
    },
    OpenDeleteFile() {
      this.filedeleteoverlay = true;
    },
    CreateBlog() {
      if(this.blogcreateformvalid && this.user && this.user.permission.map(i=>i.name).includes('blogs.create')){
        this.activeblog.author = this.user.id;
        if(this.activeblog.image){
          const formData = new FormData();
          formData.append("path", this.path);
          for (let index = 0; index < this.files.length; index++) {
            let file = this.files[index];
            formData.append("data[" + index + "]", file);
          }
          this.addFile(formData).then((response)=>{
            if(!this.multiple)
              this.activeblog.image = response.data[0].split(';')[0];
            this.addBlog(this.activeblog).then(() => {
                this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemadded'), title:this.$t('success'), type:'success'});
                this.blogcreateoverlay = false;
                this.activeblog = {};
              },() => {
                this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
              }
            );
          },() => {
            this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
          });
        }
        else{
          this.addBlog(this.activeblog).then(() => {
              this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemadded'), title:this.$t('success'), type:'success'});
              this.blogcreateoverlay = false;
              this.activeblog = {};
            },() => {
              this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
            }
          );
        }
        
      }
    },
    UpdateBlog() {
      if(this.blogupdateformvalid && this.user && this.user.permission.map(i=>i.name).includes('blogs.update')){
        if(this.activeblog.image && !this.Search.SearchResult.find(x => x.id === this.activeblog.id).image){
          const formData = new FormData();
          formData.append("path", this.path);
          for (let index = 0; index < this.files.length; index++) {
            let file = this.files[index];
            formData.append("data[" + index + "]", file);
          }
          this.addFile(formData).then((response)=>{
            if(!this.multiple)
              this.activeblog.image = response.data[0].split(';')[0];
            this.updateBlog(this.activeblog).then((response) => {
                this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemupdated'), title:this.$t('success'), type:'success'});
                this.blogupdateoverlay = false;
                this.activeblog = {};
              this.UpdateBlogs(response.data);
              },() => {
                this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
              }
            );
          },() => {
            this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
          });
        }
        else{
          this.updateBlog(this.activeblog).then((response) => {
              this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemupdated'), title:this.$t('success'), type:'success'});
              this.blogupdateoverlay = false;               
              this.activeblog = {};
              this.UpdateBlogs(response.data);
            },() => {
              this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
            }
          );
        }
      }
    },
    UpdateBlogs(payload) {
      const edited_item_index=this.Search.SearchResult.findIndex(
          (item) => item.id === payload.id
        );
      if (edited_item_index !== -1)
          this.Search.SearchResult[edited_item_index] = payload;
    },
    DeleteBlog() {
      if(this.user && this.user.permission.map(i=>i.name).includes('blogs.delete')){
        this.deleteBlog(this.activeblog).then((response) => {
            this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemdeleted'), title:this.$t('success'), type:'success'});
            this.blogdeleteoverlay = false;
            this.activeblog = {};
            if(response.data.image){
              let payload ={name:response.data.image, path:this.path};
              this.deleteFile(payload);
            }
          },() => {
            this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
          }
        );
      }
    },
    DeleteFile() {
      if(this.activeblog.image){
        let payload ={name:this.activeblog.image, path:this.path};
        this.deleteFile(payload).then(()=>{
            this.activeblog.image='';
            this.filedeleteoverlay = false;
            this.updateBlog(this.activeblog).then((response) => {
                this.$root.$emit('callNotify', { icon:'mdi-check-circle',  content:this.$t('itemdeleted'), title:this.$t('success'), type:'success'});
                this.UpdateBlogs(response.data);
              },() => {
                this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
              }
            );
          },() => {
            this.$root.$emit('callNotify', { icon:'mdi-alert',  content:this.$t('error'), title:this.$t('warning'), type:'warn'});
          }
        );
      }
    },
    ClearBlog() {
      this.activeblog={image:this.activeblog.image};
    },
    UploadFiles(files) {
      this.files=files;
      if(!this.multiple)
        this.$set(this.activeblog, 'image', this.files[0]?.name);
    },
    ...mapActions("blog", ["addBlog","updateBlog","deleteBlog"]),
    ...mapActions("file", ["addFile","deleteFile"]),
  },
};
</script>
<style scoped>
@import url("../../assets/styles/newsevents.css");
</style>